<template>
  <v-container>
    <HyphenDatatable
    :headers="headers"
    :config="{resource:'construction', title: 'Constructions', rowClickUrl:'construction', enabledActions: [ 'search', 'delete','hide-delete-action']}"
    />
  </v-container>
  
</template>



<script>
import HyphenDatatable from "./../component/HyphenDatatable.vue"

export default {
  data() {
    return {
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: 'Inquiry Type', value: 'inquiryType' },
        { text: 'Area', value: 'area' },
        { text: 'Created Date', value: 'updatedDate' },
        { text: 'Customer', value: 'customer.name' },
        { text: 'Status', value: 'status' },
        ],
        count: 'count'
    }
  },

  methods: {

    handleClick(args) {
      this.$router.push({ path: 'construction/' + args.id });
      //   this.$router.push({path:"/design/"+args.id});
    }
  },
  mounted() {
    // this.getOnSiteConsultationList();
  },
  components:{
    HyphenDatatable
  }
}
</script>


<style scoped></style>
